import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { PortableText } from "@portabletext/react"
const PrivacyAndDataPolicy = ({ data }) => {
  const {
    title: pageTitle,
    blankPage: { title, _rawContent },
  } = data?.sanityPages
  return (
    <>
      <Seo
        title={pageTitle}
        description={"All customer's/user's data keep safe and secure."}
      />
      <Layout>
        <div className="data__policy__wrapper">
          <div className="container">
            <div className="data__policy">
              <h3>{title}</h3>
            </div>
            <div>
              <div className="data__policy__content data__policy__desc">
                <PortableText value={_rawContent} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default PrivacyAndDataPolicy
export const PrivacyAndPolicy = graphql`
  query ($id: String!) {
    sanityPages(id: { eq: $id }) {
      title
      template
      handle {
        current
      }
      blankPage {
        title
        _rawContent
      }
    }
  }
`
